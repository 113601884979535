import { register } from "register-service-worker";
import { Notify, QSpinnerGears } from "quasar";

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

register(process.env.SERVICE_WORKER_FILE, {
  // The registrationOptions object will be passed as the second argument
  // to ServiceWorkerContainer.register()
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

  // registrationOptions: { scope: './' },
  updated(/* registration */) {
    Notify.create({
      message: `
        Une nouvelle version de l'application est disponible !<br>
        Veuillez actualiser la page pour l'utiliser.
      `,
      classes: "info-automatic-reload",
      position: "top",
      multiLine: true,
      html: true,
      timeout: 0,
      spinner: QSpinnerGears,
      actions: [
        {
          label: "Actualiser",
          color: "green",
          handler: () => {
            window.location.reload();
          },
        },
        {
          label: "Plus tard",
          color: "white",
          handler: () => {},
        },
      ],
    });
  },
});
