import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        name: "home",
        path: "",
        component: () => import("pages/IndexPage.vue"),
        meta: {
          cssClass: "homepage",
          afficherSelecteurExploitations: true,
        },
      },
      {
        name: "legal-mentions",
        path: "mentions-legales",
        component: () => import("pages/LegalMentionsPage.vue"),
        meta: {
          cssClass: "page-texte",
        },
      },
      {
        name: "privacy",
        path: "privacy",
        component: () => import("pages/PrivacyPage.vue"),
        meta: {
          cssClass: "page-texte",
        },
      },
      {
        name: "login",
        path: "connexion",
        component: () => import("pages/LoginPage.vue"),
        meta: {
          requiresAuth: false,
          cssClass: "page-connect",
        },
      },
      {
        name: "email-validation",
        path: "email-validation",
        component: () => import("pages/EmailValidationPage.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: "historique-commandes",
        path: "commandes",
        component: () => import("pages/HistoriqueCommandes.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "mon-profil",
        meta: {
          cssClass: "page-myProfil",
          requiresAuth: true,
        },
        children: [
          {
            name: "adherent-informations",
            path: "informations",
            component: () => import("pages/AdherentPage.vue"),
          },
          {
            name: "adherent-confidentialite",
            path: "confidentialite",
            component: () => import("pages/AdherentPage.vue"),
          },
          {
            name: "adherent-telephones",
            path: "telephones",
            component: () => import("pages/AdherentPage.vue"),
          },
        ],
      },
      {
        name: "adherent",
        path: "profil",
        component: () => import("pages/AdherentProfil.vue"),
        meta: {
          cssClass: "page-myProfil",
          requiresAuth: true,
        },
      },
      {
        name: "exploitations",
        path: "mes-exploitations",
        component: () => import("pages/ExploitationsPage.vue"),
        meta: {
          cssClass: "page-myExploitations",
          requiresAuth: true,
        },
      },
      {
        name: "creer-exploitation",
        path: "creer-exploitation",
        component: () => import("pages/ExploitationPage.vue"),
        meta: {
          cssClass: "page-myExploitations",
          requiresAuth: true,
          retour: "exploitations",
        },
      },
      {
        name: "profil-materiel",
        path: "materiel/profil/:id/",
        component: () => import("pages/MaterielProfil.vue"),
        meta: {
          cssClass: "page-myProfil",
          requiresAuth: true,
        },
      },
      {
        name: "materiels",
        path: "mon-materiel",
        component: () => import("pages/MaterielsPage.vue"),
        meta: {
          cssClass: "page-myMaterial",
          requiresAuth: true,
        },
      },
      {
        path: "materiel",
        meta: {
          cssClass: "page-myMaterial",
          requiresAuth: true,
        },
        children: [
          {
            name: "materiel-informations",
            path: "informations",
            component: () => import("pages/MaterielPage.vue"),
          },
          {
            name: "materiel-photo",
            path: "photo",
            component: () => import("pages/MaterielPage.vue"),
          },
        ],
      },
      {
        name: "recherche-adherent",
        path: "recherche-adherent",
        component: () => import("pages/RechercheAdherent.vue"),
        meta: {
          cssClass: "admin--adherents",
          requiresAuth: true,
          retour: "home",
        },
      },
      {
        name: "recherche-exploitation",
        path: "recherche-exploitation",
        component: () => import("pages/RechercheExploitation.vue"),
        meta: {
          cssClass: "admin--exploitations",
          requiresAuth: true,
          retour: "home",
        },
      },
      {
        name: "recherche-materiel",
        path: "recherche-materiel",
        component: () => import("pages/RechercheMateriel.vue"),
        meta: {
          cssClass: "page-searchMaterial",
          requiresAuth: true,
          retour: "home",
        },
      },
      // administration
      {
        path: "admin",
        component: () => import("layouts/AdminLayout.vue"),
        meta: {
          requiresAdmin: true,
        },
        children: [
          {
            name: "admin-adherents",
            path: "adherents",
            component: () => import("pages/admin/AdherentsAdmin.vue"),
            meta: {
              cssClass: "admin--adherents",
            },
          },
          {
            path: "fiche-adherent",
            meta: {
              cssClass: "page-myProfil",
              retour: "admin-adherents",
            },
            children: [
              {
                name: "admin-adherent-informations",
                path: "informations",
                component: () => import("pages/AdherentPage.vue"),
              },
              {
                name: "admin-adherent-confidentialite",
                path: "confidentialite",
                component: () => import("pages/AdherentPage.vue"),
              },
              {
                name: "admin-adherent-telephones",
                path: "telephones",
                component: () => import("pages/AdherentPage.vue"),
              },
            ],
          },
          {
            name: "admin-exploitations",
            path: "exploitations",
            component: () => import("pages/admin/ExploitationsAdmin.vue"),
            meta: {
              cssClass: "admin--exploitations",
            },
          },
          {
            path: "exploitation",
            meta: {
              cssClass: "admin--exploitation admin--adherents",
              retour: "admin-exploitations",
            },
            children: [
              {
                name: "admin-exploitation-informations",
                path: "informations",
                component: () =>
                  import("pages/admin/ExploitationCreationAdmin.vue"),
              },
              {
                name: "admin-exploitation-confidentialite",
                path: "confidentialite",
                component: () =>
                  import("pages/admin/ExploitationCreationAdmin.vue"),
              },
              {
                name: "admin-exploitation-adherents",
                path: "adherents",
                component: () =>
                  import("pages/admin/ExploitationCreationAdmin.vue"),
              },
              {
                name: "admin-exploitation-materiels",
                path: "materiels",
                component: () =>
                  import("pages/admin/ExploitationCreationAdmin.vue"),
              },
            ],
          },
          {
            name: "admin-commandes",
            path: "commandes/:code/",
            component: () => import("pages/admin/CommandesAdmin.vue"),
            meta: {
              cssClass: "admin--commandes",
            },
          },
          {
            name: "admin-historique-commande",
            path: "historique-commande/:id/",
            component: () => import("pages/admin/HistoriqueCommandeAdmin.vue"),
          },
        ],
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    name: "notFound",
    path: "/:catchAll(.*)*",
    component: () => import("pages/ErrorNotFound.vue"),
  },
];

export default routes;
